import { CloseData, TruvOrderEvent } from '.';
import { PublicMessageEventData } from './message-event-data';

export type MessageBridgeEvent =
  | {
      type: 'UNSUPPORTED_BROWSER';
      payload: { bridge_token: string };
    }
  | {
      type: 'LOAD';
      payload: { bridge_token: string };
    }
  | {
      type: 'CLOSE';
      payload: { bridge_token: string } | (CloseData & { bridge_token: string });
    };

export type OnEventCallback = <T extends PublicMessageEventData | MessageBridgeEvent | TruvOrderEvent>(
  event: T['type'],
  payload: T['payload'],
  source?: 'bridge' | 'order',
) => void;
